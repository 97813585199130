const DEFAULT_COMPILE_ENV = 'prod';
// 域名
const DefaultConfig: any = {
  dev: {
    baseUrl: '/dev',
    thirdUrl: 'http://192.168.0.17:8089',
    publicUrl: '/',
  },
  // 预发
  test: {
    baseUrl: 'https://hasee.senlianshop.net',
    bucket: 'h5pt-test',
    callbackUrl: 'https://h5pt-test.senlianshop.com',
  },
  prod: {
    baseUrl: 'https://hasee.chuangdaxx.com',
    bucket: 'fx-h5-prod',
    callbackUrl: 'https://h5pt.chuangdaxx.com',
  },
};
/** 图片地址 */
const IMGURL = {

};
export {
  IMGURL,
  DefaultConfig,
  DEFAULT_COMPILE_ENV,
};
